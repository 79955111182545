import React, { useState } from 'react';

function YouTubeEmbed({ videoId, title, type, height = '300px' }) {
  const [isPlaying, setIsPlaying] = useState(false);

  // Handle play button click to replace thumbnail with video iframe
  const handlePlay = () => {
    setIsPlaying(true);
  };

  // YouTube thumbnail URL for the video
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

  // YouTube embed URL for the video
  const getEmbedUrl = (videoId) => {
    return `https://www.youtube.com/embed/${videoId}?modestbranding=1&rel=0&controls=1&showinfo=0`;
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* Show thumbnail and play button before video starts */}
      {!isPlaying && (
        <div>
          <img
            src={thumbnailUrl}
            alt="Video Thumbnail"
            width="100%"
            height="250px"
            style={{ borderRadius: '10px', cursor: 'pointer' }}
            onClick={handlePlay} // Clicking on the thumbnail triggers video play
          />
          {/* Play button overlay on the thumbnail */}
          <div
            onClick={handlePlay}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '50%',
              padding: '10px',
              cursor: 'pointer',
            }}
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="white"
              viewBox="0 0 16 16"
            >
              <path d="M4.5 3.5L12 8 4.5 12V3.5z" />
            </svg> */}
          </div>
        </div>
      )}

      {/* Embed the video iframe only after clicking play */}
      {isPlaying && (
        <iframe
          width="100%"
          height={height}
          src={getEmbedUrl(videoId)}
          title={title}
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ borderRadius: '10px', marginBottom: '1px' }}
        />
      )}

      {/* Title and Type Display */}
      <div className="d-flex justify-content-between align-items-center mt-2">
        <span className="text-light" style={{ fontWeight: 'bold' }}>
          {title}
        </span>
        <span className="text-secondary">{type}</span>
      </div>
    </div>
  );
}

export default YouTubeEmbed;
