import React, { useState, useRef } from 'react';
import Lander from './Media/Lander.mp4';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Masonry from 'react-masonry-css'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import YouTubeEmbed from './YoutubeEmbed';

const Links = [
    {
        Title: "AL MUHAIDIB",
        Type: "Documentary",
        Link: "https://www.youtube.com/watch?v=rjBkDACCubM"
    },
    {
        Title: "Al Rajhi Bank",
        Type: "Commercial",
        Link: "https://www.youtube.com/watch?v=Jl6bJvP6LUY"
    },
    {
        Title: "Bed quarter",
        Type: "Commercial",
        Link: "https://www.youtube.com/watch?v=WWB1guztXEs"
    },
    {
        Title: "SFC",
        Type: "Commercial",
        Link: "https://www.youtube.com/watch?v=7lItVY8foYM"
    },
    {
        Title: "Li Beirut - A Melody of Hope",
        Type: "Commercial",
        Link: "https://www.youtube.com/watch?v=mjG23H8Vc1s"
    },
    {
        Title: "Simah - Brand Film",
        Type: "Commercial",
        Link: "https://www.youtube.com/watch?v=9wPboWggJUI"
    },
    {
        Title: "UNESCO MEDIA",
        Type: "Commercial",
        Link: "https://www.youtube.com/watch?v=h49n4kDBNng"
    },
    {
        Title: "NAYIFAT COMMERCIAL SUPERMARKET",
        Type: "Commercial",
        Link: "https://www.youtube.com/watch?v=we1tgmxUgDE"
    },
    {
        Title: "NAYIFAT FLIGHT COMMERCIAL",
        Type: "Commercial",
        Link: "https://www.youtube.com/watch?v=14h9D8tGjQk"
    },
    {
        Title: "BUJAIRI RAMADAN FILM",
        Type: "Commercial",
        Link: "https://www.youtube.com/watch?v=wOwBvsUy3o4"
    },
    {
        Title: "RED SEA EDIT 3",
        Type: "Content",
        Link: "https://www.youtube.com/shorts/6aQd1Ps8MKs"
    },
    {
        Title: "RED SEA EDIT 2",
        Type: "Content",
        Link: "https://www.youtube.com/shorts/LFusgzR0Xco"
    },
    {
        Title: "RED SEA EDIT",
        Type: "Content",
        Link: "https://www.youtube.com/shorts/_hGhCNLYlj4"
    },
    {
        Title: "BUJAIRI WALKTHROUGH",
        Type: "Content",
        Link: "https://www.youtube.com/shorts/x5vBUAzQzjw"
    },
    {
        Title: "MONA MOSLY FILM DIRIYAH ",
        Type: "Content",
        Link: "https://www.youtube.com/shorts/TG1tnjIiqLk"
    },
    {
        Title: "ETHAR FILM DIRIYAH",
        Type: "Content",
        Link: "https://www.youtube.com/shorts/LSh8t1P45cQ"
    },
    {
        Title: "BUJAIRI RAMADAN FILM",
        Type: "Commercial",
        Link: "https://www.youtube.com/watch?v=wOwBvsUy3o4"
    },
    {
        Title: "KHAFJI",
        Type: "Documentary",
        Link: "https://www.youtube.com/watch?v=WaE0S1B7MyI"
    },
    {
        Title: "SFC TOUR",
        Type: "Content",
        Link: "https://www.youtube.com/shorts/kraFE3K-ns4"
    },
    {
        Title: "CAFE BOULUD",
        Type: "Content",
        Link: "https://www.youtube.com/shorts/Vkd6IIbySxQ"
    }



]

function Home() {
    const [showTitle, setShowTitle] = useState(true);
    const videoRef = useRef(null);
    const [key, setKey] = useState('all');
    const [loadingStatus, setLoadingStatus] = useState(Links.map(() => true));

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            const currentTime = videoRef.current.currentTime;
            if (currentTime >= 41.5 && showTitle) setShowTitle(false);
            else if (currentTime < 1 && !showTitle) setShowTitle(true);
        }
    };

    const getVideoId = (url) => {
        const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|shorts\/))([^?&\/]+)/);
        return match ? match[1] : null;
    };

    const filteredLinks = key === 'all' ? Links : Links.filter(link => link.Type.toLowerCase() === key);

    const breakpointColumns = {
        default: 3,
        1100: 2,
        700: 1,
    };

    return (
        <ParallaxProvider>
            <div className="container-fluid">
                {/* Video Section */}
                <section className="row">
                    <div className="col-12 videoContainer px-0">
                        <video
                            ref={videoRef}
                            className="video"
                            muted
                            loop
                            autoPlay
                            src={Lander}
                            style={{ height: '100vh' }}
                            onTimeUpdate={handleTimeUpdate}
                        />
                    </div>
                    <div className="col-12 Title overlay d-flex align-items-center justify-content-center text-light" style={{
                        
                        backgroundColor: 'rgba(0,0,0, 0.6)',
                        fontSize: 'var(--title)',
                    }}>
                        Ibrahim Azar
                    </div>
                </section>

                {/* Tabs Section */}
                <section className="container pb-5 text-light">
                    <div className="row">
                        <div className="col-12 text-center" style={{ fontSize: 'var(--subTitle)', color: 'white' }}>
                            Our Work
                        </div>
                    </div>
                    <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 d-flex justify-content-around mt-5" style={{ borderBottom: 'none' }}>
                        <Tab eventKey="all" title="All" />
                        <Tab eventKey="commercial" title="Commercials" />
                        <Tab eventKey="documentary" title="Documentaries" />
                        <Tab eventKey="content" title="Content" />
                    </Tabs>

                    {/* Render Links */}
                    <Masonry
                        breakpointCols={breakpointColumns}
                        className="masonry-grid"
                        columnClassName="masonry-grid_column"
                    >
                        {filteredLinks.map((item, index) => {
                            const videoId = getVideoId(item.Link);
                            return (

                                <div className="masonry-item position-relative">
                                    <YouTubeEmbed videoId={videoId} link={item.Link} title={item.Title} type={item.Type} height={index % 2 === 0 ? '300px' : '300px'} />


                                </div>

                            );
                        })}
                    </Masonry>
                </section>
            </div>
        </ParallaxProvider>
    );
}

export default Home;

